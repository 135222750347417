<template>
  <div
    class="MiniItem --global-clickable"
    :class="{ 'is-selected': follows(lifeskill.number) }"
    @click="toggle()">
    <LifeskillNumberTag
      class="tag"
      :lifeskill-id="lifeskillId"
      :isCategorySection="true" />

    <div class="label">
      {{ $t(`dashboard.general.lifeskillLabels.${lifeskillId}`) }}
    </div>

    <div class="selectionIndicator">
      <div class="selectionIcon">
        +
      </div>
      <div class="checkmark">
        <svgicon
          class="svg-icon"
          icon="checkmark" />
      </div>
    </div>
  </div>
</template>

<script>
import LifeskillNumberTag from '@/modules/dashboard/components/LifeskillNumberTag';
import { mapGetters } from 'vuex';

export default {
    components: { LifeskillNumberTag },
    props: {
        lifeskillId: {
            type: String,
            required: true
        }
    },
    computed: {
      ...mapGetters({
        'follows': 'moduleApp/follows'
      }),
        lifeskill() {
            return this.$store.state.moduleApp.lifeskills[this.lifeskillId];
        }
    },
    methods: {
        toggle() {
            this.$store.dispatch('moduleApp/toggleLifeskillFollow', { lifeskillId: this.lifeskillId });

            this.$emit('change-count');

            this.$forceUpdate();
        }
    }
};
</script>

<style lang="scss" scoped>
$selectionColor: #5081ff;

.MiniItem {
    display: flex;
    align-items: center;
    padding: 0.6em 0;

    color: rgba(white, 0.8);
    font-weight: 300;

    border-bottom: 1px solid rgba(white, 0.05);

    &.is-selected {
        .checkmark {
            display: flex;
        }
        .selectionIcon {
            display: none;
        }
    }
}

.tag {
    margin-right: 1em;
}
.label {
    flex: 1;
}
.selectionIndicator {
    margin-left: 1em;
    width: 2em;
    display: flex;
    justify-content: center;
}
.selectionIcon {
    color: rgba(white, 0.5);
}
.checkmark {
    display: none;
    $size: 1.2em;
    width: $size;
    height: $size;
    background-color: $selectionColor;
    border-radius: 50%;

    justify-content: center;
    align-items: center;

    .svg-icon {
        fill: white;
        $size: 40%;
        width: $size;
        height: $size;
    }
}
</style>
