<template>
  <div class="CategorySection">
    <div class="widthWrapper">
      <div class="header">
        <div class="title">
          {{ $t(`dashboard.general.categoryLabels.${categoryId}`) }} {{ lifeskillRange }}
        </div>
      </div>

      <div class="items">
        <MiniItem
          v-for="lifeskillId of lifeskillIds"
          :key="lifeskillId"
          :lifeskill-id="lifeskillId"
          @change-count="$emit('change-count')" />
      </div>
    </div>
  </div>
</template>

<script>
import { getLifeskillRangeFromCategoryId } from '@/utils';
import MiniItem from './MiniItem';

export default {
    components: { MiniItem },
    props: {
        categoryId: {
            type: String,
            required: true
        }
    },
    computed: {
        lifeskillIds() {
            return this.$store.getters['moduleApp/getCategoryById'](this.categoryId).lifeskills;
        },
        lifeskillRange() {
            return getLifeskillRangeFromCategoryId(this.categoryId).join('-');
        }
    }
};
</script>

<style lang="scss" scoped>
.CategorySection {
    color: white;
    padding: 2em;

    background: $dashboard-sectionGradient;
}

.header {
    padding-top: 1em;
    display: flex;
    align-items: center;
}
.title {
    font-size: 120%;
    color: rgba(white, 0.9);
}

.widthWrapper {
    margin: auto;
}

.items {
    margin-top: 2em;
}

@include media('>820px') {
    .widthWrapper {
        max-width: 60em;
    }
    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .MiniItem {
        width: calc(#{percentage(1 / 2)} - 1em);
    }
}
</style>
