<template>
  <div class="Footer">
    <div class="widthWrapper">
      <div v-if="!hidePie">
        <PieGraph
          :data="categoryAggregates"
          hide-icons />
      </div>
      <div class="text">
        {{ $tc('dynamicText', selectionsCount, { count: selectionsCount }) }}
      </div>

      <StandardButton
        class="nextButton"
        :disabled="!selectionsCount"
        :label="buttonLabel"
        icon="arrow-right"
        @click="$emit('click')" />
    </div>
  </div>
</template>

<translations>
  dynamicText: 'You have chosen {count} skill | You have chosen {count} skills'
  dynamicText_no: 'Du har valgt {count} ferdighet | Du har valgt {count} ferdigheter'
</translations>

<script>
import StandardButton from '@/components/StandardButton';
import PieGraph from '../DashboardProfileView/PieGraph';

export default {
    components: { StandardButton, PieGraph },
    props: {
        buttonLabel: {
            type: String
        },
        selectionsCount: {
            type: Number,
            default: 0
        },
        hidePie: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        categoryAggregates() {
            return Object.entries(this.$store.state.moduleApp.categories).map(([categoryId, category]) => {
                return {
                    categoryId,
                    braindates: category.lifeskills
                        .map(lifeskillId => this.$store.state.moduleApp.lifeskills[lifeskillId])
                        .filter(lifeskill => this.$store.getters['moduleApp/follows'](lifeskill.number)).length
                };
            });
        }
    },
    watch: {
        selectionsCount: function () {
            this._computedWatchers.categoryAggregates.run();
            this.$forceUpdate();
        }
    }
};
</script>

<style lang="scss" scoped>
.Footer {
    background-color: $dashboard-darkSectionBackground;
    padding: 1em;
    color: white;
}
.widthWrapper {
    max-width: 60em;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    color: rgba(white, 0.8);
    flex: 1;
}

.PieGraph {
    $size: 4em;
    width: $size;
    height: $size;
    margin-right: 1em;
}
</style>
