<template>
  <div class="RiveAura">
    <canvas ref="canvas" id="auraCanvas" class="riveContainer" width="800px" height="800px" />
  </div>
</template>


<script>
const rive = require("rive-js");
import Loading from '@/assets/rive/lifeskills-favorites-loading.riv'
import Outro from '@/assets/rive/lifeskills-favorites-outro.riv'

export default {

  props: {
      animationNumber: {
          type: Number,
          default: 1
      }
  },
  
  computed: {
      getRiveAnimation(){
        return this.animationNumber === 1? Loading : Outro
      }
  },

  data() {
    return {
      r: null,
    };
  },

  mounted() {
    this.setRive();
  },

  beforeDestroy(){
   this.r.stop();
   this.r.unsubscribeAll();
   this.r = null;
  },

  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: this.getRiveAnimation,
          canvas: this.$refs.canvas,
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
      }); 
      const _this = this;
      this.r.on('stop', function() {
        _this.$emit('stop');
      });
    },
  },
  
};
</script>

<style scoped lang="scss">

.riveContainer {
  @media (max-width: 620px) {
    width: 110vw;
  }
}

</style>
