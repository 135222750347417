<template>
  <div class="PieGraph">
    <svg
      ref="svg"
      class="svg"
      width="400"
      xheight="400"
      viewBox="0 0 400 400">
      <symbol
        v-for="category of categories"
        :id="`x-${category.categoryId}`"
        :key="category.categoryId"
        viewBox="0 0 64 64">
        <svgicon
          v-if="!hideIcons"
          :icon="`categories/${category.categoryId}`" />
      </symbol>
      <filter id="negative"><feColorMatrix values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0" /></filter>
    </svg>
  </div>
</template>

<script>
import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import categoryThemes from '@/category-themes';

const d3 = {
    select,
    arc,
    pie
};

export default {
    props: {
        data: {},
        hideIcons: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        categories() {
            return this.data;
        }
    },
    watch: {
        data: {
            handler: function (val, oldVal) {
                this.setupPie();
            },
            deep: true
        }
    },
    mounted() {
        this.setupPie();
    },
    methods: {
        setupPie() {
            const svg = select(this.$refs.svg);
            const width = +svg.attr('width');
            const height = width; // +svg.attr('height');
            const radius = Math.min(width, height) / 2;

            if (this.g) {
                this.g.remove();
            }
            const g = svg.append('g').attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
            this.g = g;

            const pie = d3
                .pie()
                .sort(null)
                .value(function (d) {
                    return d.population;
                });

            const path = d3
                .arc()
                .outerRadius(radius - 10)
                .innerRadius(0);

            this._pie = pie;

            const label = d3
                .arc()
                .outerRadius(radius - 40)
                .innerRadius(radius - 40);

            const data = this.categories.map(x => {
                return {
                    age: x.categoryId,
                    population: x.braindates
                };
            });

            const arc = g.selectAll('.arc').data(pie(data)).enter().append('g').attr('class', 'arc');
            this._arc = arc;

            arc.append('path')
                .attr('d', path)
                .attr('fill', function (d) {
                    return categoryThemes[d.data.age || 'default'].color;
                });

            // arc.append('image')
            //   .attr('transform', function (d) {
            //     return 'translate(' + label.centroid(d) + ')'
            //   })
            //   .attr('height', 30)
            //   .attr('width', 30)
            //   .attr('x', -15)
            //   .attr('y', -15)
            //   .attr('filter', 'url(#negative)')
            //   .attr('xlink:href', function (d) {
            //     // return getHashedUrl(`/dashboard/images/category-icons/${d.data.age}.svg`);
            //   })

            arc.append('use')
                .attr('transform', function (d) {
                    return 'translate(' + label.centroid(d) + ')';
                })
                .attr('height', 30)
                .attr('width', 30)
                .attr('x', -15)
                .attr('y', -15)
                // .attr('filter', 'url(#negative)')
                .attr('fill', 'white')
                .attr('color', 'white')
                .attr('xlink:href', function (d) {
                    // return '#svg-sync'
                    return `#x-${d.data.age}`;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.PieGraph {
}
.svg {
    display: block;
    width: 100%;
    max-width: 30em;
    margin: auto;
}
</style>
