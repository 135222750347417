<template>
  <div class="DashboardLifeskillsVoteView">
    <div class="scrollContainer">

      <div class="title">
        {{ $t('title') }}
      </div>
      <div class="subtitle">
        {{ $t('subtitle') }}
      </div>

      <div class="categories">
        <CategorySection
        class="categorySection"
          v-for="categoryId of categoryIds"
          :key="categoryId"
          :category-id="categoryId"
          @change-count="changeCount" />
      </div>
    </div>
    <Footer
    class="footer"
      :button-label="$t('nextButton')"
      :selections-count="numberOfSelections"
      @click="gotoNext()" />

      <LifeskillFavoritesRive v-if="showLoadingAnimation" class="riveAnimation" :animationNumber="2" />
  </div>
</template>

<translations>
  title: 'Customize the Memolife Universe'
  subtitle: 'Make lifelong learning easy - expand your interests when you like with recommended content from 7 galaxies and 100 solar systems.'

  title_no: 'Skreddersy Memolife-universet'
  subtitle_no: 'Gjør livslang læring enkelt - utvid interessene dine når det passer deg med anbefalt innhold fra 7 galakser og 100 solsystemer.'

  nextButton: 'Done'
  nextButton_no: 'Ferdig'
</translations>

<script>
import CategorySection from './CategorySection';
import Footer from './Footer';
import LifeskillFavoritesRive from '../../components/LifeskillsFavoritesRive';

export default {
    components: { CategorySection, Footer, LifeskillFavoritesRive },
    computed: {
        categoryIds() {
            return Object.keys(this.$store.state.moduleApp.categories);
        },
        numberOfSelections() {
          return this.$store.getters['moduleApp/getMarkedLifeskills'].length;
        }
    },
    data(){
        return {
            showLoadingAnimation: false,
        };
    },
    methods: {
        gotoNext() {
            this.showLoadingAnimation = true;
            setTimeout(() => {
                 this.$router.push({
                    name: 'DashboardHomeView'
                });
            }, 2500);
        },

        changeCount() {
            this._computedWatchers.numberOfSelections.run();

            this.$forceUpdate();
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardLifeskillsVoteView {
    background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
    display: flex;
    flex-direction: column;
}

.scrollContainer {
    overflow-y: auto;
    padding-top: 2em;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.categorySection {
    background: none;
}

.footer {
    background-color: #0a133583;
}

.title {
    font-weight: 600;
    margin-top: 1.5em;
    color: rgba(white, 0.9);
    font-size: 200%;
    text-align: center;
}

.subtitle {
    color: rgba(white, 0.7);
    margin-top: 1em;
    line-height: 1.5em;
    text-align: center;
    max-width: 40em;
    padding: 0 1em;
}

.categories {
    width: 100%;
}

.riveAnimation {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom,#0a1335, #1e1456);
    width: 100vw;
    height: 100vh;
    z-index: 100000;
}

@include media('<tablet') {
    .title {
        font-size: 140%;
    }
}
</style>
